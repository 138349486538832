import { Layout, SEO } from 'components';
import  availableDatePicker  from 'components/AvailableDatePicker';
import React from 'react';

const tuitionBooking = () => {
  return (
  <Layout>
      <SEO title="Tuition Booking" />
      <div>this is above the date picker
      {availableDatePicker}
      <availableDatePicker 
      name="fred"
      date="yesterday"/>
      </div>
  </Layout>
  );
};

export default tuitionBooking;
